import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LeftChevronIcon from '../images/icons/arrow-left.png';

const QuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png';

const NotFoundPage = () => (
  <div className="pw-not-found">
    <div className="w3-display-container pw-nf-texture">
      <img loading="lazy" src={QuotesGroupIcon} className="pw-nf-top-right-texture w3-hide-small" />
      <Layout>
        <SEO title="404: Not found" />
        <div className="w3-content w3-display-container">
          <Link to="/" className="pw-nf-back-to w3-hide-small">
            <img src={LeftChevronIcon} className="pw-nf-back-icon" />
            <div>
              Back to HomePage
            </div>
          </Link>
          <Link to="/" className="pw-nf-close w3-hide-medium w3-hide-large" />
        </div>
        <div className="w3-content w3-center pw-nf-content-section">
          <div className="pw-nf-oops-icon" />
          <h1>Oops!</h1>
          <div className="pw-nf-message">The page you’re looking for does not exist. But there are plenty of others that do. <br />Try again!</div>
        </div>
      </Layout>
    </div>
  </div>
)

export default NotFoundPage
